import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { setBtnSound } from '../../../store/CommonSlice';
import { setMic } from '../../../store/SpeakingSlice';
import { Button } from '../../_atoms/Button';
import { Div } from '../../_atoms/Div';
import { Image } from '../../_atoms/Image';
import { Paragraph } from '../../_atoms/Paragraph';
import { Frame } from '../../_molecules/Frame';
import { ImageButton } from '../../_molecules/ImageButton';

const Rabbit = styled(motion.img)`
  width: 33%;
  height: auto;
  position: absolute;
  right: -2vw;
  bottom: -19vw;
`;

export const SpeakingIntroTemplate = () => {
  const state = useSelector((state) => state.speaking);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [error, setError] = useState(<>Listen to the story and follow the sentence!</>);

  useEffect(() => {
    navigator.mediaDevices.getUserMedia({ audio: true })
        .then((stream) => {
            // ����ũ ��Ʈ���� ����Ǹ� ����ũ ���¸� Ȱ��ȭ
            stream && dispatch(setMic(true));
        })
        .catch((error) => {
            // ����ũ�� ���� ���
            console.error('Error accessing the microphone:', error);
            if (error.name === 'NotFoundError' || error.name === 'DevicesNotFoundError') {
                setError(<>Your microphone is not connected.<br />Please connect your microphone.</>);
                dispatch(setMic(false));
            } 
            // ������ �ź����� ���
            else if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
                setError(<>Please allow microphone permission.</>);
                dispatch(setMic(false));
            } 
            // ��Ÿ ���� ó��
            else {
                setError(<>An error occurred while trying to access the microphone. Please try again later.</>);
                dispatch(setMic(false));
            }
        });
  }, []);

  console.log(state.mic);

  return (
    <Frame type="speakingInfo">
      <Rabbit 
        src="https://content-cdn.chungchy.com/Phonics_Hero/images/common/speaking_rabbit.png" 
        alt="speaking rabbit" 
        initial="normal"
        animate={{
          x: 0, y: [0, -25, 0, -25, 0], z: 0,
          transition : {
            duration: 3,
            ease: "easeInOut",
            times: [0, 0.2, 0.5, 0.8, 0.5, 1],
            repeat: Infinity,
            repeatDelay: 1
          }
        }}
      />
      <ImageButton
        src='https://content-cdn.chungchy.com/Phonics_Hero/images/common/btn_pre_150px.png'
        alt='back'
        type='back'
        name='left'
        onClick={() => {
          dispatch(setBtnSound(true));
          navigate("/book/readers");
        }}
      />
      <Div type="speaking"><Image type="speaking" src="https://content-cdn.chungchy.com/Phonics_Hero/images/common/Speaking.png" alt="Speaking" /></Div>
      {state.mic ? <Paragraph type="speaking" label="Let's listen to the story and follow the sentence!"></Paragraph> : <Paragraph type="speaking" label={error}></Paragraph>}
      {state.mic ? <Button type="start" onClick={() => {dispatch(setBtnSound(true)); navigate("/book/readers/speaking/start");}}>Start</Button> : <Button type="start" disabled={!state.mic}>Start</Button>}
    </Frame>
  );
};